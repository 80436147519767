/* Regular */
@font-face {
  font-family: 'BrunelSans-Regular';
  src: url('/public/fonts/BrunelSans-Regular.woff2') format('woff2'),
       url('/public/fonts/BrunelSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: 'BrunelSans-Bold';
  src: url('/public/fonts/BrunelSans-Bold.woff2') format('woff2'),
       url('/public/fonts/BrunelSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: 'BrunelSans-Medium';
  src: url('/public/fonts/BrunelSans-Medium.woff2') format('woff2'),
       url('/public/fonts/BrunelSans-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: 'BrunelSans-Regular', Arial, sans-serif;
  font-size: 14px; /* Smaller base font size */
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #353f49;
  line-height: 1.6;
}

h1 {
  font-family: 'BrunelSans-Bold', Arial, sans-serif;
  font-size: 20px;
}

h3 {
  font-family: 'BrunelSans-Medium', Arial, sans-serif;
  font-size: 16px;
}


#version {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #808080; /* Grey color */
  font-size: 12px;
}

.header {
  position: relative;
  height: 70px;
  background-color: #353f49;
}

.header img {
  padding: 20px;
}

.content-container {
  width: 75%;
  margin: auto;
  text-align: left;
  color: #353f49;
  padding: 20px 0;
}

.content-container p {
  line-height: 1.6em;
  max-width: 80%;
  color: #353f49;
  margin: 0;
  padding-bottom: 20px;
}

.region-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.region-selector {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
}

.region-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.region-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  text-decoration: none;
  color: #353f49;
  text-align: center;
}

.region-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.region-item h3 {
  margin: 0;
  font-size: 18px;
}

/* Report Detail Container */
.report-detail {
  max-width: 900px;
  margin: 40px auto; /* Center on page with space */
  padding: 20px;
  /*background-color: #f9f9f9; /* Light background for contrast */
  /*border-radius: 10px; /* Rounded corners */
  /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow */ 
  color: #353f49;
}

/* Title and Subtitle */
.report-detail h1 {
  font-size: 28px; /* Larger main title */
  color: #000; /* Accent color */
  margin-bottom: 10px;
  font-family: 'BrunelSans-Medium', Arial, sans-serif;
}

.report-detail h2 {
  font-size: 20px; /* Slightly larger subtitle */
  color: #555;
  margin-bottom: 20px;
}

/* Report Detail Image */
.report-detail-image {
  width: 100%;
  max-width: 300px; /* Larger image */
  margin: 20px auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some depth */
}

/* Content Sections */
.report-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd; /* Subtle border for separation */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Minimal shadow */
}

.report-section h3 {
  font-size: 18px; /* Larger section headings */
  margin-bottom: 10px;
  color: #353f49;
}

.report-section p {
  font-size: 14px;
  line-height: 1.6; /* Increase readability */
  margin-bottom: 10px;
}

/* Video Section */
.report-video {
  margin-top: 15px;
  text-align: center; /* Center the video */
}

.report-video video {
  width: 100%;
  max-width: 600px; /* Constrain video width */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add depth */
}

/* Quote Section */
.report-quote {
  margin-top: 30px;
  padding: 20px;
  border-left: 4px solid #000; /* Accent border */
  background-color: #eef5ff; /* Light blue background */
  border-radius: 8px;
}

.report-quote blockquote {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  color: #353f49;
}

.report-quote .quote-person {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.report-quote .quote-person-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Enhance person image */
}

.report-quote .quote-person strong {
  font-size: 14px;
  color: #353f49;
}

/* FAQ Section */
.report-faq {
  margin-top: 30px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}



.report-faq ul {
  font-size: 14px;
  line-height: 1.6;
  margin-left: 20px;
  color: #555;
  list-style-type: disc;
}

.faq-container {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  font-weight: bold;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f4f4f4;
}

.faq-answer {
  padding: 10px;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 8px;
  background: #ffffff;
}

.chatbot-embed-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chatbot-embed-container h2 {
  font-family: 'BrunelSans-Bold', Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
}

.chatbot-iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* For tablet-sized devices */
@media (max-width: 900px) {
  .region-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For mobile devices */
@media (max-width: 600px) {
  .region-grid {
    grid-template-columns: 1fr;
  }
}

.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin: 0 auto;
  max-width: 1500px;
}

.tile {
  position: relative;
  width: calc(33.33% - 40px);
  margin: 20px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #5d656d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  text-decoration: none;
  color: #353f49;
  display: flex;
  flex-direction: column;
}

.tile:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tile img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.tile h2 {
  margin-top: 0;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: normal;
}

.tile p {
  margin-top: 5px;
}


@media (max-width: 768px) {
  .region-item, .tile {
    width: calc(100% - 40px);
  }
  .content-container, .region-list {
    width: 80%;
  }
  .content-container p {
    max-width: 100%;
  }
}

.rectangle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rectangle-item {
  width: 640px;
  margin: 16px 0;
  padding: 20px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #5d656d;
  background-color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #353f49;
}

.rectangle-item h3 {
  margin: 0;
}

.description-container {
  width: 75%;
  margin: auto; /* Center the description container horizontally */
  text-align: left;
  color: #353f49;
}


.description-container p {
  margin: 0; /* Reset default margins */
  padding-bottom: 20px;
  color: #353f49;
  font-size: 14px;
  line-height: 1.6em;
  max-width: 80%;
  color: #353f49;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Distribute space between buttons */
  margin-top: 15px;
}
.button {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #353f49;
  padding: 10px;
  width: auto;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
}
.button a {
  color: inherit;
}

.button.instructions-button {
  background-color: #fff;
  border-radius: 0px;
  border-width: 0px;
}
/* App Showcase styles */
.app-container {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
padding: 20px;
margin: 10px 10px 10px 10px auto; /* Center the app container horizontally */
max-width: 1500px; /* Adjust the max-width as needed */
}

/* Category Section */
.category-section {
  margin-bottom: 2rem;
}

.category-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 0.5rem;
}

/* Apps in Category */
.apps-in-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Single report specific adjustment */
.apps-in-category > .app:first-child:last-child {
  flex: 0 1 100%; /* Single item takes full width */
  max-width: 600px; /* Optional: Limit the maximum width */
  margin: 0 auto; /* Center the single item */
}

.instructions-container {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
padding: 20px;
margin: 0 auto; /* Center the app container horizontally */
max-width: 1200px; /* Adjust the max-width as needed */
}

.app-container .app {
cursor: pointer;
}

.app-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adds space between the images */
  margin-top: auto;
}

.app-image {
  max-width: 100%;
  height: auto;
  width: 200px; /* Restore the original width */
  margin-top: 10px;
  padding: 10px;
}

.app-qr {
  max-width: 100%;
  height: auto;
  width: 100px; /* Restore the original width */
  margin-top: 10px;
  padding: 10px;
}

.app {
position: relative;
width: calc(50% - 100px);
margin: 20px;
background-color: #EBF3F5;
box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
transition: box-shadow 0.3s;
text-decoration: none;
color: #353f49;
display: flex;
flex-direction: column; /* Ensure text starts from top */
min-height: 300px;
justify-content: space-between;
}

.app p {
  padding: 10px;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
}

.app h3 {
margin-top: 5px;
margin-bottom: 5px;
max-width: calc(100% - 100px); /* Adjust the value as per your layout */
overflow: hidden;
white-space: normal; /* Allow wrapping onto a new line */
}

.app p {
margin-top: 5px; /* Adjust as per your design */
margin-bottom: 10px;
flex-grow: 1;
}

.app i {
font-size: 48px; /* Adjust the icon size */
margin-bottom: 10px; /* Add some space below the icon */
}

.powered-by-logo {
position: absolute;
top: auto; /* Adjust top position as needed */
right: 10px; /* Adjust right position as needed */
max-width: 80px;
height: auto;
max-height: 40px;
}

.instructions {
position: relative;
width: calc(100% - 100px);
margin: 20px;
padding: 15px;
background-color: #fff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: box-shadow 0.3s;
text-decoration: none;
color: #353f49;
display: flex;
flex-direction: column; /* Ensure text starts from top */
}

.instructions h3 {
margin-top: 0;
margin-bottom: 10px;
max-width: calc(100% - 100px); /* Adjust the value as per your layout */
overflow: hidden;
white-space: normal; /* Allow wrapping onto a new line */
}

.instructions p {
margin-top: 5px; /* Adjust as per your design */
}


.disclaimer-text {
  font-family: 'BrunelSans-Bold', Arial, sans-serif;
  font-weight: bold;
}

.right-buttons {
  display: flex; /* Use flexbox */
  align-items: center; /* Center align items */
}


/* Hover effect */
.app:hover, .button:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
}

/* Media query for smaller displays */
@media (max-width: 768px) {
  .app {
    width: calc(100% - 0px); /* Full width on smaller displays */
  }
  .rectangle-item {
    width: calc(80% - 40px); /* Full width on smaller displays */
  }
  .app-container {
    justify-content: center; /* Center the blocks on smaller screens */
    padding: 0
  }
  .rectangle-container {
    justify-content: center; /* Center the blocks on smaller screens */
  }
  .description-container {
  width: 80%;
  }
  .rectangle-container {
  width: 80%;
  }
  .description-container p {
  max-width: 100%;
  }
  .instructions {
    width: calc(100% - 0px);
  }
}
